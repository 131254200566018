import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import urlBuilder from "@sanity/image-url"

export default ({ article, defaultImage }) => {
  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const articleWrapper = css`
    display: flex;
    flex-flow: column wrap;
  `

  const articleImageWrapper = css`
    width: 100%;

    /* @media (min-width: 768px) {
      width: 50%;
    } */
  `
  const articleImage = css`
    object-fit: cover;
    margin-bottom: var(--size-1);
  `

  const articleText = css`
    color: var(--colour-text);
    width: 100%;

    /* @media (min-width: 768px) {
      width: calc(50% - var(--size-4));
      margin: 0 0 0 var(--size-4);
    } */

    p {
      font-size: var(--size-1);
    }
  `

  const articleTitle = css`
    font-size: var(--size-3);
  `

  return (
    <div css={articleWrapper}>
      <Link css={articleImageWrapper} to={`/blog/${article.slug.current}`}>
        {/* <img
            css={articleImage}
            alt=""
            fluid={article.mainImage.asset.fluid}
          /> */}

        {article.mainImage.imageUpload && (
          <img
            css={articleImage}
            sizes="(min-width: 800px) 500px, 100vw"
            srcSet={[
              urlFor(article.mainImage.imageUpload.asset.id)
                .auto("format")
                .width(1600)
                .height(960)
                .fit("crop")
                .focalPoint(
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.x,
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.y
                )
                .url() + ` 1000w`,
              urlFor(article.mainImage.imageUpload.asset.id)
                .auto("format")
                .width(1000)
                .height(600)
                .fit("crop")
                .focalPoint(
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.x,
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.y
                )
                .url() + ` 1000w`,
              urlFor(article.mainImage.imageUpload.asset.id)
                .auto("format")
                .width(500)
                .height(300)
                .fit("crop")
                .focalPoint(
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.x,
                  article.mainImage.imageUpload.hotspot == null
                    ? 0.5
                    : article.mainImage.imageUpload.hotspot.y
                )
                .url() + ` 500w`,
            ]}
            alt={
              article.mainImage.imageDescription
                ? article.mainImage.imageDescription
                : " "
            }
          />
        )}
      </Link>

      <div css={articleText}>
        <Link to={`/blog/${article.slug.current}`}>
          <h3 css={articleTitle}>{article.title}</h3>
        </Link>
        <small>{article.publishDate}</small>
      </div>
    </div>
  )
}
