import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ArticlePreview from "../components/ArticlePreview"
import { css } from "@emotion/core"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }

    allSanityBlogPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          author {
            name
          }
          categories {
            title
          }
          publishedAt
          mainImage {
            imageName
            imageDescription
            imageUpload {
              asset {
                fluid {
                  srcSet
                }
                id
              }
              hotspot {
                x
                y
              }
            }
          }
        }
      }
    }
  }
`

const BlogIndex = ({ data, location, ...props }) => {
  const posts = data.allSanityBlogPost.edges

  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()

  const blogWrapper = css`
    max-width: 1000px;
    margin: var(--size-4);

    @media (min-width: 1048px) {
      margin: var(--size-10) auto;
    }
  `
  const articleList = css`
    margin: var(--size-8) 0 0 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    > * {
      width: 100%;
      @media (min-width: 800px) {
        width: 45%;
      }
    }
  `

  const paginationStyles = css`
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
  `

  const paginationNumberStyles = css`
    display: flex;
    flex: 0 1 auto;
  `

  return (
    <Layout location={location}>
      <SEO title="Blog" description="The latest articles from Tri-Rivington" />

      <div style={{ background: "#fff" }}>
        {/* <div className={styles.hero}>Blog</div> */}
        <div css={blogWrapper}>
          {/* <h2>Recent articles</h2> */}
          <div css={articleList}>
            {posts.map(({ node }) => {
              return (
                <article
                  css={css`
                    margin-bottom: var(--size-8);
                  `}
                  key={node.slug.current}
                >
                  <ArticlePreview
                    article={node}
                    // defaultImage={defaultBlogImage}
                  />
                </article>
              )
            })}
          </div>
          <ul css={paginationStyles}>
            <li>
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  Previous
                </Link>
              )}
            </li>
            <div css={paginationNumberStyles}>
              {Array.from({ length: numPages }, (_, i) => (
                <li
                  key={`pagination-number${i + 1}`}
                  style={{
                    margin: "0 var(--size-1)",
                  }}
                >
                  {i === 0 ? (
                    ""
                  ) : (
                    <Link
                      to={`/blog/${i === 0 ? "" : i + 1}`}
                      style={{
                        padding: "0 calc(var(--size-1)*.5)",
                        textDecoration: "none",
                        color: i + 1 === currentPage ? "#ffffff" : "",
                        background: i + 1 === currentPage ? "#007acc" : "",
                      }}
                    >
                      {i + 1}
                    </Link>
                  )}
                </li>
              ))}
            </div>
            <div>
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Next
                </Link>
              )}
            </div>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex
